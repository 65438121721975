  /*
   * Copyright(c) 2021 architectural award, All Rights Reserved.
   * Author: lxj
   */
  import fetch from '@/utils/fetch';

  export default {
    // 登录
    login: data => {
      return fetch({
        url: 'manage/login',
        method: 'post',
        data
      })
    },
    // 获取分配的项目列表
    getDistributionProjects: params => {
      return fetch({
        url: 'inquiry/competition-judge-project',
        method: 'get',
        params
      })
    },
    // 获取参赛作品列表
    getProjects: params => {
      return fetch({
        url: 'inquiry/competition-projects',
        method: 'get',
        params
      })
    },
    // 获取项目信息
    getProjectInfo: id => {
      return fetch({
        url: `inquiry/competition-projects/${id}`,
        method: 'get'
      })
    },
    // 添加/修改评分
    saveCompetitionScore: data => {
      return fetch({
        url: 'competition-score/save',
        method: 'post',
        data
      })
    },
    // 获取已评分数
    getCompetitionScoreCount: params => {
      return fetch({
        url: 'inquiry/competition-score-count',
        method: 'get',
        params
      })
    },
    // 获取已评分项目数
    getCompetitionScoredCount: params => {
      return fetch({
        url: 'inquiry/competition-scored-count',
        method: 'get',
        params
      })
    },
    // // 获取后台用户信息
    // getCompetitionUser: params => {
    //   return fetch({
    //     url: 'inquiry/competition/user',
    //     method: 'get',
    //     params
    //   })
    // },
  }